$headings: h1 h2 h3 h4 h5 h6;

@media all and (max-width: 576px) {
  h1.article-title {
    font-size: map-get($heading-sizes-xs, 'h1')
  }
  h3.article-subtitle {
    font-size: map-get($heading-sizes-xs, 'h3')
  }


  .mtm-logged-in {
    .fixed-top {
      position: absolute;
      top: 32px;
      margin-top: 0 !important;
    }
  }
}


@media all and (max-width: 782px) {
  .mtm-logged-in {
    .fixed-top {
      margin-top: 40px !important;
    }
  }
}

@media all and (max-width: 600px) {
  .mtm-logged-in {
    .fixed-top {
      position: absolute;
      top: 32px;
      margin-top: 0 !important;
    }
  }
}

@media all and (max-width: 768px) {
  .mtm-logged-in {
    .navbar-collapse {
      margin-top: 46px;
    }
  }
}

@media all and (max-width: 992px) and (min-width: 769px) {
  .mtm-logged-in {
    .navbar-collapse {
      margin-top: 32px;
    }
  }
}
