
.mtm-logged-in {
  .fixed-top {
    margin-top: 32px !important;
  }
}

.short-header {
  height: 420px;
  background-position: top center;

  .brand {
    margin-top: 5rem;
  }
}

.mtm-category-pills {
  justify-content: center;
  li {
    margin-bottom: .5rem;
  }
}

.mtm-cut-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.os-animation {
  opacity: 0;
}