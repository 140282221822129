.navbar-brand {
  img {
    height: 35px;
  }
}

.navbar {
  img.show-transparent {
    display: none;
  }
}


.navbar.navbar-transparent {
  .show-transparent {
    display: initial;
  }
  .hide-transparent {
    display: none;
  }
}