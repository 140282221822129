.card-profile {
  .card-avatar {
    width: 100px;
    max-width: 100px;
    max-height: 100px;
  }
}
.mtm-featured-post {
  .card-title {
    height: 50px;
    overflow: hidden;
  }
  .card-footer {
    justify-content: flex-end;
  }
}

.mtm-category-card {
  .card-body {
    display: grid;
    grid-template-rows: 75px auto auto;
    height: 280px;
    align-items: baseline;
  }
}

.card {
  @include transition-all-bezier();
  .card-description {
    .card-profile {
      .card-avatar {
        width: 50px;
      }
    }
  }
   &.post-card {
     &:hover {
       @include box-shadow($bmd-shadow-8dp);
       transform: scale(1.05);
     }
     .card-description {
       max-height: 48px;
       overflow: hidden;
     }
     .card-title {
       font-size: $font-size-h4;
       height: 3 * $font-size-h4;
       overflow: hidden;
       font-weight: 300;
       font-family: $font-family-sans-serif;
     }
   }
}

.card-profile {
  .card-avatar {
    &.mtm-avatar-large {
      width: 200px;
      min-width: 200px;
      min-height: 200px;
      @include transition-all-bezier();
      &:hover {
        transform: scale(1.1);
      }
    }
    &.mtm-card-usp {
      width: 145px;
      max-width: 145px;
      max-height: 145px;
      box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.156), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    }
  }
}

.mtm-category-card-desc {
  height: 4.5rem;
  overflow: hidden;
  margin-top: 1rem;


  .card-profile {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    width: auto;
    margin-left: -25px;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.card-background {
  &.card-background-mtm-black {
    &::after {
      background: $mtm-filter-bg-black;
    }
  }
  &.card-background-mtm-blue {
    &::after {
      background: $mtm-filter-bg-blue;
      @include transition-all-bezier();
    }

    &:hover {
      &::after {
        background: $mtm-filter-bg-blue-hover;
        @include transition-all-bezier();
      }
    }
  }

  &.card-background-mtm-white {
    .card-title {
      color: $primary;
      text-shadow: $mtm-text-shadow-blue;
    }

    .card-description {
      color: $primary !important;
      text-shadow: $mtm-text-shadow-blue;
    }

    &::after {
      background: $mtm-filter-bg-white;
    }
  }
}