$mtm-blue: #51239F !default;
$mtm-purple: #226BAB !default;

// Tints
$mtm-blue-1000: #51239f !default;
$mtm-blue-900: #6238a8 !default;
$mtm-blue-800: #734eb2 !default;
$mtm-blue-700: #8565bb !default;
$mtm-blue-600: #967bc5 !default;
$mtm-blue-500: #a891cf !default;
$mtm-blue-400: #b9a7d8 !default;
$mtm-blue-300: #cabde2 !default;
$mtm-blue-200: #dcd3eb !default;
$mtm-blue-100: #ede9f5 !default;

// Shades
$mtm-blue-a: #51239f !default;
$mtm-blue-b: #481f8f !default;
$mtm-blue-c: #401c7f !default;
$mtm-blue-d: #38186f !default;
$mtm-blue-e: #30155f !default;
$mtm-blue-f: #28114f !default;
$mtm-blue-g: #200e3f !default;
$mtm-blue-h: #180a2f !default;
$mtm-blue-i: #10071f !default;
$mtm-blue-j: #08030f !default;
$mtm-blue-k: #000000 !default;

// tints
$mtm-purple-1000: #226bab;
$mtm-purple-900: #3879b3;
$mtm-purple-800: #4e88bb;
$mtm-purple-700: #6497c4;
$mtm-purple-600: #7aa6cc;
$mtm-purple-500: #90b5d5;
$mtm-purple-400: #a6c3dd;
$mtm-purple-300: #bcd2e5;
$mtm-purple-200: #d2e1ee;
$mtm-purple-100: #e8f0f6;

$primary:       $mtm-blue-800;
$info:          $mtm-purple-700;

$brand-primary: $primary;
$brand-info: $info;
