%mtm-filter {
  position: relative;
  z-index: 1;
  & > div {
    z-index: 2;
  }
}

%mtm-filter-after {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
}

.mtm-filter {
  @extend %mtm-filter;
  &:after {
    @extend %mtm-filter-after;
    border-radius: $border-radius-large;
  }

  &.mtm-blue {
    h1, h2, h3 {
      color: $white-color;
    }
    .title {
      color: $white;
    }

    .description {
      color: $white;
    }
    &::after {
      background: $mtm-filter-bg-blue;
    }
  }

  &.mtm-black {
    &::after {
      background: $mtm-filter-bg-black;
    }
  }

  &.mtm-white {
    &::after {
      background: $mtm-filter-bg-white;
    }
  }
}