$heading-sizes-xs: (
        h1: 2rem,
        h2: 1.19rem,
        h3: 1rem,
        h4: 9rem,
        h5: .8rem,
        h6: 0.7rem
);
$font-paragraph-xs: 12px !default;
$font-size-navbar-xs: 14px !default;
$font-size-small-xs: 10px !default;

$font-post-paragraph: 16px;
