.footer, .footer-big {
  .badge-category {
    display: flex;
    align-items: baseline;

    .badge {
      display: inline-block;
      margin-right: .5rem;
    }
  }

  a {

    i, .badge {
      opacity: .8;
      transition: all ease .3s;

    }

    p {
      transition: all ease .3s;
    }

    &:hover {
      i, .badge {
        opacity: 1;
      }
      p {
        color: darken($gray-light, 90%)
      }
    }
  }

}
