.post-content {
  img {
    max-width: 100%;
    height: auto;
  }

  .wp-block-image {
    margin: 2rem 0;
  }
  p {
    font-size: $font-post-paragraph;
  }


  h2 {
    margin-top: 60px;
  }

  h3 {
    margin-top: 40px;
  }

  h4 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.crayon-syntax .crayon-main {
  margin: 10px;
}

.comment-reply-title {
  text-align: center;
}

.service-steps {
  .card {
    height: 270px;
  }

  .step-actors {
    margin-top: -45px;

    img {
      background: #EBF4F5;
      width: 30%;
      max-width: 100px;
      border-radius: 50%;
      box-shadow: $bmd-shadow-2dp;
    }
  }

  .step-down {
    width: 100%;
  }

  .step-next {
    padding-right: 2rem;
    max-width: 75px;
  }

  .step-number {
    font-size: $font-size-h1;
    color: $mtm-blue-900;
  }

  .step-action {
    font-size: $font-size-h2;
    color: $mtm-blue-900;
    margin: 2rem 0 1rem;
    display: inline-block;
  }
}

.blockquote {
  position: relative;
  padding-left: 40px;
  &:before {
    content: '\201C';
    font-size: 6rem;
    position: absolute;
    top: 28px;
    left: 5px;
    color: #eee;
  }
}

li.title-H2 {
  margin-top: 10px;
}

li.title-H3 {
  font-size: 90%;
  margin-left: 15px;
}

li.title-H4 {
  font-size: 80%;
  margin-left: 30px;
}

li.title-H5, li.title-H6 {
  font-size: 70%;
  margin-left: 45px;
}
