.short-header {
  height: 420px;
  background-position: top center;

  .brand {
    margin-top: 5rem;
  }
}

.login-header {
  height: auto;
  min-height: 100vh;
  @media all and (max-height: 760px) {
    & {
      padding-top: 100px;
    }
  }
}

.mtm-page-header {
  height: auto;
  min-height: 100vh;

  @media all and (max-width: 767px) {
    & {
      padding-top: 110px;
    }
  }

  @media all and (max-height: 660px) {
    & {
      padding-top: 110px;
    }
  }
}

.short-header-category, {
  @extend .short-header;
  height: 100vh;
}


@media all and (max-width: 576px) {
  .short-header, .short-header-category {
    height: auto;
  }
}